<script setup lang="ts">
// const { data: value } = await useLazyFetch('/api/config?key=maintenance');

useHead({
	title: 'Diskubre Travel Official Website',
	meta: [
		{
			name: 'description',
			content: 'Book your tours, transportation and activities with Diskubre Travel.',
		},
	],
	script: [
		{
			key: 'jsonld-business-only',
			type: 'application/ld+json',
			children: businessOnly(),
		},
	],
});
// onMounted(() => {
// 	if (value.value.value === 1) {
// 		navigateTo('/maintenance');
// 	}
// });
</script>

<template>
	<div>
		<MainBanner />
		<CurrateCards />

		<BestSeller />
		<Destinations />
	</div>
</template>
