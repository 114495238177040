<template>
	<div class="banner-wrapper text--white">
		<v-container class="main-container">
			<!-- -----------------------------------------------
            Start Banner
        ----------------------------------------------- -->

			<div class="text-sm-left text-center bg-banner">
				<v-row>
					<v-col cols="12" sm="6" md="6">
						<h1 class="banner-title font-weight-bold">Explore Palawan Island with us.</h1>
						<h4 class="banner-subtitle font-weight-regular">Select wide selection of transfers, tours and activities to explore our island, Palawan!</h4>

						<div class="mt-md-16 d-sm-flex d-xs-flex">
							<v-btn to="/transportation" color="primary" class="action mr-2 mr-sm-5 mb-5 mb-sm-0 d-sm-flex" size="small" flat>Transfers</v-btn>
							<v-btn size="small" to="/tours" class="action d-sm-flex mr-2 mr-sm-5 mb-5 mb-sm-0" color="warning" flat>Tours</v-btn>
						</div>
					</v-col>

					<v-col cols="12" sm="6" md="6" class="d-flex justify-center">
						<trip-advisor-reviews></trip-advisor-reviews>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</div>
</template>

<style scoped>
.bg-banner {
	background-color: white;
	opacity: 0.8;
	border-radius: 5px;
	padding: 20px 20px;
}
.banner-title {
	color: black;
	font-size: 30px;
	mix-blend-mode: darken;
}

.banner-subtitle {
	color: black;
	font-size: 20px;
	mix-blend-mode: darken;
}
.action {
	min-width: 100px !important;
	max-width: 100px !important;
	padding: 12px 15px;
}
</style>
